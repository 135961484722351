<template>
  <div>
    <b-card-code>
      <b-card-text class="mb-0">
        <h3>{{ roleName }}</h3>
        <span>Assigned Permissions</span>
      </b-card-text>
      <!-- role's permissions -->
      <div class="demo-inline-spacing">
        <b-badge
          variant="primary"
          v-for="(permission, index) in roleAssignedPermission"
          :key="index"
        >
          {{ permission }}
        </b-badge>
      </div>
    </b-card-code>
    <b-card-code title="Assign Permissions To Role">
      <b-row>
        <!-- select permissions box -->
        <b-col :md="12">
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="text"
            :options="option"
          />
        </b-col>
        <!-- assign permission button -->
        <b-col :md="4">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="assign"
            >
              Assign Permissions
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-code>
    <!-- success handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showSuccessleAlert"
      @dismissed="showSuccessAlert = false"
      variant="success"
    >
      <h4 class="alert-heading">
        Success
      </h4>
      <div class="alert-body">
        <span>{{ success_message }}</span>
      </div>
    </b-alert>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- empty permission message -->
    <b-modal
      id="modal-warning"
      v-model="modalShow"
      title="Warrning"
      ok-title="Accept"
      ok-only
      ok-variant="warning"
      modal-class="modal-warning"
      centered
    >
      Please select pirmissions first
    </b-modal>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code'
export default {
  components: {
    BCardCode,
  },
  
  
  data() {
    return {
      id: 0,
      roleName: '',
      roleAssignedPermission: [],
      success_message: '',
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      errors_back: [],
      errors_assign: {},
      option: [],
      dir: 'ltr',
      selected: [],
      permissions: [],
      modalShow: false,
    }
  },
  methods: {
    // assign function to submit assign permissions to role
    assign() {
      this.permissions = []
      this.showDismissibleAlert = false
      for (let select in this.selected) {
        this.permissions.push(this.selected[select].value)
      }
      if (this.permissions.length > 0) {
        axios
          .post('role/permissions/add', {
            role: this.id,
            permissions: this.permissions,
          })
          .then((result) => {
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500,
            })

            
            this.$store.dispatch('User/getAuthenticatedUserWithPermissions')
            this.$router.push({
              path: '/Roles',
            })
          })
          .catch((err) => {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: 'Error!',
              showConfirmButton: false,
              timer: 1500,
            })
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      } else {
        this.modalShow = !this.modalShow
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    // request role data
    this.id = this.$route.params.id
    axios
      .get('role/' + this.id)
      .then((result) => {
        const data = result.data.data
        this.roleName = data.name
        for (let index in data.permissions) {
          this.roleAssignedPermission.push(data.permissions[index].name)
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    // request all permissions data
    axios
      .get('permission/index')
      .then((result) => {
        const data = result.data.data
        for (let index in data) {
          if (!this.roleAssignedPermission.includes(data[index].name)) {
            this.option.push({
              value: data[index].id,
              text: data[index].name,
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>

<style lang="scss">
 
</style>
